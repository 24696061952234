import Version from "@gdk/version";
import "./modernizr-gdk-custom";

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        Modernizr: any
    }
}
document.addEventListener("DOMContentLoaded", function() {
    const features : any = window.Modernizr;
    const body : HTMLElement = document.body;
    const ua : string = window.navigator.userAgent;

    if (features.touchevents === true) {
        body.parentElement.classList.add("touch");

        if (ua.indexOf("iPad") > 0 || ua.indexOf("iPhone") > 0 || ua.indexOf("Android") > 0) { /* empty */ } else {
            body.parentElement.classList.remove("touch");
            body.parentElement.classList.add("no-touch");
        }
    }

    if (features.touchevents === false) {
        body.parentElement.classList.add("no-touch");
    }

    if (features.canvas) {
        body.parentElement.classList.add("canvas");
    }

    if (features.svg) {
        body.parentElement.classList.add("svg");
    }
});

const component = "Base";
const versions = [
    { version: "4.29.0", release: "9.19.24"},
    { version: "4.28.0", release: "9.12.24"},
    { version: "4.27.1", release: "8.29.24"},
    { version: "4.27.0", release: "7.3.24"},
    { version: "4.26.1", release: "5.23.24"},
];
const description = "Base Package";

Version.initGdkNPM(component, versions, description);