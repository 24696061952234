import * as utils from "@gdk/utils";
import BaseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import { IBaseComponentOptions } from "@gdk/base-component";
import appState from "@gdk/app-state";

const component = "Modal";
const versions = [
    { version: "2.8.0", release: "4.18.24"},
    { version: "2.7.4", release: "2.29.24"},
    { version: "2.7.3", release: "2.24.23"},
    { version: "2.7.2", release: "11.11.22"},
    { version: "2.7.1", release: "9.23.22"}
];

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Modal : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "modalType",
        isRequired: false,
        validate: "type",
        possibleValues: ["string"],
        errorMessage: ["GDK Modal : modalType must be a string"]
    },
    {
        setting: "autoShow",
        isRequired: false,
        validate: "type",
        possibleValues: ["boolean"],
        errorMessage: ["GDK Modal : autoShow must be a boolean"]
    },
    {
        setting: "overlayShouldCloseModal",
        isRequired: false,
        validate: "type",
        possibleValues: ["boolean"],
        errorMessage: ["GDK Modal : overlayShouldCloseModal must be a boolean"]
    },
    {
        setting: "hideCloseButton",
        isRequired: false,
        validate: "type",
        possibleValues: ["boolean"],
        errorMessage: ["GDK Modal : hideCloseButton must be a boolean"]
    },
    {
        setting: "onOpened",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Modal : onOpened must be a function"]
    },
    {
        setting: "onClosed",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Modal : onClosed must be a function"]
    }

];

const modalCloseBtn = "<button class='btn-close icon-close' type='button' aria-label='Close modal'></button>";

export interface IModalOptions extends IBaseComponentOptions {
    modalType?: string;
    autoShow?: boolean;
    overlayShouldCloseModal?: boolean;
    hideCloseButton?: boolean;
    onOpened?: Function;
    onClosed?: Function;
}

/**
 * @desc GDK Modal JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var modal = new GDK.Modal({
 * 	"content" : "#your-modal-id",
 * 	"autoShow" : false,
 * 	"onOpened" : function(){
 * 		console.log("model has opened");
 * 	},
 * 	"onClosed" : function(){
 * 		console.log("model has closed");
 * 	}
 * });
 *
 * @example <caption>JS Examples - Add event listener to trigger button and open modal</caption>
 * var modal1Btn = document.getElementById("modal-1-btn");
 *
 * modal1Btn.addEventListener('click', function(){
 * 	modal.show();
 * });
 */
class GdkModal {
    _internalVars: {
        modalType: string;
        modalTabHandler: any;
        inModalArray: HTMLElement[];
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        node: HTMLElement;
        container: HTMLElement;
        closeBtn: HTMLElement;
        onOpened: Function;
        onClosed: Function;
    };

    readonly _defaults: object;

    readonly _options: IModalOptions;

    /**
     * These are settings for the instantiation.
     * @param {string|Object} content
     * A reference to the modal node
     *
     * @param {string} [modalType='fullScreen']
     * Use to set Modal type - 'fullScreen' or 'dialogueBox'
     *
     * @param {boolean} [autoShow=false]
     * Use if you want to model to auto show when instantiated
     *
     * @param {boolean} [overlayShouldCloseModal=true]
     * Set to false if you do not want the overlay to have click to close functionality
     *
     * @param {boolean} [hideCloseButton]
     * Set to true if you want to hide the close button in the upper right corner of the modal
     *
     * @param {function} [onOpened]
     * A callback function that gets fired when the modal has been opened
     *
     * @param {function} [onClosed]
     * A callback function that gets fired when the modal has been closed
     *
     */
    constructor(options: IModalOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node: null,
            container: null,
            modalType: null,
            closeBtn: null,
            contentType: null,
            inModalArray: null,
            modalTabHandler: null,
            onOpened: null,
            onClosed: null
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            modalType: "fullScreen",
            autoShow: false,
            overlayShouldCloseModal: true,
            hideCloseButton: false
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = BaseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (BaseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = BaseComponent.getContentType(this);
            setLocalVars.call(this);
            buildModal.call(this);
            setEvents.call(this);

            if (this._options.autoShow) {
                this.show();
            }
        }
    }

    //Public Methods

    /**
     * @desc Shows the modal
     */
    show(): void {
        this._internalVars.node.style.display = "block";

        setTimeout(() => {
            this._internalVars.node.classList.add("modal--show");
            this._internalVars.node.classList.add("modal--animate");
            if (this._options.onOpened)
                this._options.onOpened();
        }, 100);

        setTimeout(() => {
            this._internalVars.node.classList.remove("modal--animate");
        }, 850);

        this._internalVars.inModalArray = [];
        document.addEventListener("keyup", this._internalVars.modalTabHandler);

        const bodyScrollTopPos = document.documentElement.scrollTop;
        const bodyScrollLeftPos = document.documentElement.scrollLeft;
        window.onscroll = function() {
            window.scrollTo(bodyScrollLeftPos, bodyScrollTopPos);
        };
    }

    /**
     * @desc Hides the modal
     */
    hide(): void {
        this._internalVars.inModalArray = [];
        document.removeEventListener("keyup", this._internalVars.modalTabHandler);

        this._internalVars.node.classList.add("modal--hide");

        setTimeout(() => {
            this._internalVars.node.style.display = "none";
            this._internalVars.node.classList.remove("modal--show");
            this._internalVars.node.classList.remove("modal--hide");
            if (this._options.onClosed)
                this._options.onClosed();
        }, 800);

        window.onscroll = () => undefined;
    }

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }
}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars(): void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    // set functions
    this._internalVars.setState = setState.bind(this);
    this._internalVars.mobileStart = mobileStart.bind(this);
    this._internalVars.clickStart = clickStart.bind(this);
    this._internalVars.pullStart = pullStart.bind(this);
    this._internalVars.pullMove = pullMove.bind(this);
    this._internalVars.pullEnd = pullEnd.bind(this);
    this._internalVars.hide = this.hide.bind(this);
    this._internalVars.modalClicked = modalClicked.bind(this);
    this._internalVars.modalTabHandler = isolateTab.bind(this);

    // set variables
    this._internalVars.containerClass = ".modal-container";
    this._internalVars.container = this._internalVars.node.querySelector(this._internalVars.containerClass);

    this._internalVars.contentClass = ".modal-content";
    this._internalVars.content = this._internalVars.node.querySelector(this._internalVars.contentClass);
}

/**
 * setEvents()
 * Sets all the events needed for this component
 */
function setEvents(): void {

    if (this._options.modalType === "fullScreen") {
        this._internalVars.setState();

        this._internalVars.scrollTab.addEventListener("touchstart", this._internalVars.mobileStart);
        this._internalVars.scrollTab.addEventListener("touchmove", this._internalVars.pullMove);
        this._internalVars.scrollTab.addEventListener("touchend", this._internalVars.pullEnd);

        this._internalVars.scrollTab.addEventListener("mousedown", this._internalVars.clickStart);
        this._internalVars.scrollTab.addEventListener("mousemove", this._internalVars.pullMove);
        this._internalVars.scrollTab.addEventListener("mouseleave", this._internalVars.pullEnd);
        this._internalVars.scrollTab.addEventListener("mouseup", this._internalVars.pullEnd);
    }

    if (!this._options.hideCloseButton) {
        this._internalVars.closeBtn.addEventListener("click", this._internalVars.hide);
    }

    if (this._options.overlayShouldCloseModal) {
        this._internalVars.node.addEventListener("click", this._internalVars.modalClicked);
    }
}

/**
 * removeEvents()
 * removes all events from this component
 */
function removeEvents(): void {
    if (this._options.overlayShouldCloseModal) {
        this._internalVars.node.removeEventListener("click", this._internalVars.modalClicked);
    }
    if (this._internalVars.closeBtn) {
        this._internalVars.closeBtn.removeEventListener("click", this._internalVars.hide);
    }
    if (this._internalVars.scrollTab) {
        this._internalVars.scrollTab.removeEventListener("touchstart", this._internalVars.mobileStart);
        this._internalVars.scrollTab.removeEventListener("touchmove", this._internalVars.pullMove);
        this._internalVars.scrollTab.removeEventListener("touchend", this._internalVars.pullEnd);

        this._internalVars.scrollTab.removeEventListener("mousedown", this._internalVars.clickStart);
        this._internalVars.scrollTab.removeEventListener("mousemove", this._internalVars.pullMove);
        this._internalVars.scrollTab.removeEventListener("mouseleave", this._internalVars.pullEnd);
        this._internalVars.scrollTab.removeEventListener("mouseup", this._internalVars.pullEnd);
    }
}

/**
 * buildModal()
 * Adds needed markup this component
 */
function buildModal(): void {

    if (this._options.hideCloseButton !== true) {
        const modalContainer = this._internalVars.node.querySelector(".modal-container");
        modalContainer.insertAdjacentHTML("afterbegin", modalCloseBtn);

        this._internalVars.closeBtn = this._internalVars.node.querySelector(".btn-close");
    }

    if (this._options.modalType === "fullScreen") {

        this._internalVars.container.classList.add("modal--full-screen");
        const scrollTabDiv = document.createElement("div");
        const innerDiv = document.createElement("div");

        this._internalVars.container.insertBefore(scrollTabDiv, this._internalVars.container.children[0]);
        scrollTabDiv.appendChild(innerDiv);
        scrollTabDiv.classList.add("scroll-tab");

        this._internalVars.scrollTabClass = "scroll-tab";
        this._internalVars.scrollTab = this._internalVars.node.querySelector(`.${this._internalVars.scrollTabClass}`);

        this._internalVars.content.querySelector(".modal--call-to-action-bar") && this._internalVars.content.classList.add("modal-content-with-cta-bar");

    } else {
        this._internalVars.container.classList.add("modal--dialog-box");
    }



    const spanTab = document.createElement("span");
    spanTab.setAttribute("tabindex", "0");
    spanTab.setAttribute("class", "tab-filler");
    const cloneSpanTab = spanTab.cloneNode(true);
    this._internalVars.container.appendChild(spanTab);
    this._internalVars.container.insertBefore(cloneSpanTab, this._internalVars.container.children[0]);
}

function setState(): void {
    appState.windowSize = { width: window.innerWidth || document.documentElement.clientWidth, height: window.innerHeight || document.documentElement.clientHeight };
    const html = document.documentElement;
    this._internalVars.isTouchDevice = html.classList.contains("touch");
}


function mobileStart(e: TouchEvent): void {
    if (window.innerWidth < 769) {
        e.preventDefault();
        this._internalVars.touchobj = e.changedTouches[0]; // reference first touch point
        this._internalVars.startY = parseInt(this._internalVars.touchobj.clientY); // get y coord of touch point
        this._internalVars.isTouchDevice = true;
        this._internalVars.pullStart();
    }
}

function clickStart(e: MouseEvent): void {
    if (window.innerWidth < 769) {
        e.preventDefault();
        this._internalVars.startY = e.pageY; // get x coord of mouse point
        this._internalVars.mouseIsDown = true;
        this._internalVars.pullStart();
    }
}

function pullStart(): void {
    const containerRect = this._internalVars.container.getBoundingClientRect();
    this._internalVars.startingPosition = parseInt(containerRect.top);
    this._internalVars.pullStarted = true;
}

function pullMove(e: MouseEvent | TouchEvent): void {
    if (window.innerWidth < 769 && this._internalVars.pullStarted === true) {
        e.preventDefault();
        let dist: number;
        if (e instanceof TouchEvent && this._internalVars.isTouchDevice === true) {
            this._internalVars.touchobj = e.changedTouches[0]; // reference first touch point
            dist = parseInt(this._internalVars.touchobj.clientY) - this._internalVars.startY; // calculate dist traveled by touch point
        } else if (e instanceof MouseEvent) {
            dist = e.pageY - this._internalVars.startY; // calculate dist traveled by mouse
        }

        const maxScroll = this._internalVars.container.clientHeight - 100;
        const position = this._internalVars.startingPosition as number + dist;

        if (position < maxScroll) {
            if (this._internalVars.isTouchDevice === true) {
                this._internalVars.container.style.top = `${position}px`;
            } else if (this._internalVars.mouseIsDown === true) {
                this._internalVars.container.style.top = `${position}px`;
            }
        }

        this._internalVars.scrollClose = (position > maxScroll - 100) ? true : false;
    }
}

function pullEnd(): void {
    this._internalVars.node.classList.add("modal--animate");
    this._internalVars.container.removeAttribute("style");
    this._internalVars.mouseIsDown = false;
    this._internalVars.pullStarted = false;
    this._internalVars.isTouchDevice = false;

    if (this._internalVars.scrollClose === true) {
        this.hide();
        this._internalVars.scrollClose = false;
    }

    setTimeout(() => {
        this._internalVars.node.classList.remove("modal--animate");
    }, 850);
}

function modalClicked(e: MouseEvent): void {
    if ((e.target as HTMLElement).classList.contains("modal")) {
        this.hide();
    }
}

function isolateTab(e: KeyboardEvent): void {
    if (e.keyCode == 9 || (e.keyCode == 9 && e.shiftKey)) {
        if (utils.isChild("modal", document.activeElement) && !document.activeElement.classList.contains("tab-filler") && (this._internalVars.inModalArray.indexOf(document.activeElement) < 0)) {
            this._internalVars.inModalArray.push(document.activeElement);
        }

        if (document.activeElement.classList.contains("tab-filler")) {
            if (this._internalVars.inModalArray.length > 0) {
                this._internalVars.inModalArray[0].focus();
            }
        }
    }
}

Version.initGdkNPM(component, versions, GdkModal);

export { GdkModal };