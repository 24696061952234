import Version from "@gdk/version";

const component = "Styles";
const description = "Styles Package";
const versions = [
    { version: "6.11.2", release: "7.11.24"},
    { version: "6.11.1", release: "5.16.24"},
    { version: "6.11.0", release: "4.4.24"},
    { version: "6.10.0", release: "2.15.24"},
    { version: "6.9.0", release: "2.1.24"}
];

Version.initGdkNPM(component, versions, description);