//global vars
const html = document.getElementsByTagName('html')[0];

// Module Imports
import * as utils from '@gdk/utils';

let appState = {

    mode			: 'null',
    navMode         : 'null',
    isTouchDevice	: isTouchDevice(),

    size : {
        windowWidth : null,
        windowHeight : null
    },

    set windowSize(obj) {
        this.size.windowWidth = obj.width;
        this.size.windowHeight = obj.height;
        setMode(this.size.windowWidth);
        setNavMode(this.size.windowWidth);
    },

    get windowSize() {
        return this.size;
    },

};


/**
 * setMode()
 * Sets the appState mode depending on window side
 *
 * @param {Number} Window Width
 */
function setMode(w):void {
    if(w >= 768){
        appState.mode = "desktop";
    }else{
        appState.mode = "mobile";
    }
}

function setNavMode(w):void {
    if(w >= 1000){
        appState.navMode = "desktop";
    }else{
        appState.navMode = "mobile";
    }
}

/**
 * isTouchDevice()
 * Sets the appState mode depending on window side
 *
 * @return {Bool}
 */
function isTouchDevice(): boolean {
    return utils.hasClass(html, 'touch');
}

export default appState;