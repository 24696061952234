import baseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import {IBaseComponentOptions} from "@gdk/base-component";

const component = "Button Switch";
const versions = [
    { version: "3.2.0", release: "10.28.22"},
    { version: "3.1.3", release: "7.22.22"},
    { version: "3.1.1", release: "4.01.22"},
    { version: "3.1.0", release: "3.11.22"},
    { version: "3.0.0", release: "2.25.22"}
];

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Button Switch : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "initiallyChecked",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Button Switch : initiallyChecked must be set to a boolean"]
    },
    {
        setting                 :   "onSwitchChange",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK Button Switch : onSwitchChange must be a function"]
    }
];

export interface IButtonSwitchOptions extends IBaseComponentOptions {
    initiallyChecked?: boolean;
    onSwitchChange?: (buttonSwitchCheckedState: boolean) => void;
}

/**
 * @desc GDK Button Switch JavaScript Class
 *
 * @example <caption>JS Instantiation</caption>
 * var buttonSwitch = new GDK.ButtonSwitch({
 *     content: "#your-switch-button-id",
 *     initiallyChecked : false,
 *     onSwitchChange : function(buttonSwitchCheckedState) {
 *          if (buttonSwitchCheckedState) {
 *              console.log("Button switch checked");
 *          } else {
 *              console.log("Button switch unchecked");
 *          }
 *     }
 * });
 */
class GdkButtonSwitch {
    _internalVars: {
        node: HTMLElement;
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        buttonSwitchInput: HTMLInputElement;
        buttonSwitchToggle: HTMLInputElement;
    };

    readonly _defaults: {
        initiallyChecked: boolean;
    };

    readonly _options: IButtonSwitchOptions;

    /**
     * @param {Object} options Settings for the instantiation.
     *
     * @param {string|HTMLElement} options.content A reference to the component node
     *
     * @param {string} [options.initiallyChecked=false] Used to designate if button switch is initially checked
     *
     * @param {function} [options.onSwitchChange] A callback function triggered when the button switch is changed
     */
    constructor(options: IButtonSwitchOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node: null,
            contentType: null,
            buttonSwitchInput: null,
            buttonSwitchToggle: null
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            initiallyChecked: false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            setInitialState.call(this);
        }
    }

    //Public Methods
    /**
     * @desc Returns a boolean, "true" if the button switch is checked & "false" if unchecked
     *
     * @returns {boolean}
     *
     * @example
     * buttonSwitch.getSwitchCheckedState();
     */
    getSwitchCheckedState() : boolean {
        return this._internalVars.buttonSwitchInput.checked;
    }

    /**
     * @desc Toggles the button switch
     *
     * @example
     * buttonSwitch.toggleSwitch();
     */
    toggleSwitch() : void {
        if (this.getSwitchCheckedState()) {
            updateClass.call(this,"button-switch-active", this._internalVars.buttonSwitchToggle, true);
            setCheckboxState.call(this,false);
        } else {
            updateClass.call(this,"button-switch-active", this._internalVars.buttonSwitchToggle, false);
            setCheckboxState.call(this,true);
        }
    }

    /**
     * @desc Removes the node from the dom and any events attached
     *
     * @example
     * buttonSwitch.destroy();
     */
    destroy() : void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        for (const variableKey in this) {
            const hasVariableKey = Object.prototype.hasOwnProperty.call(this, variableKey);
            if (hasVariableKey) {
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
function setEvents() : void {
    this._internalVars.buttonSwitchToggle.addEventListener("click", this._internalVars.switchToggleHandler);
    this._internalVars.buttonSwitchToggle.addEventListener("keyup", this._internalVars.switchToggleHandler);
}

function removeEvents() : void {
    this._internalVars.buttonSwitchToggle.removeEventListener("click", this._internalVars.switchToggleHandler);
    this._internalVars.buttonSwitchToggle.removeEventListener("keyup", this._internalVars.switchToggleHandler);
}

function updateClass(className: string, element: HTMLElement, remove: boolean) : void {
    if (remove) {
        element.classList.remove(className);
    } else {
        element.classList.add(className);
    }
}

function setCheckboxState(isChecked: boolean) : void {
    if (isChecked) {
        this._internalVars.buttonSwitchInput.checked = true;
    } else {
        this._internalVars.buttonSwitchInput.checked = false;
    }
}

function setInitialState() : void {
    if (this._internalVars.buttonSwitchToggle) {
        this._internalVars.buttonSwitchToggle.setAttribute("tabIndex", "0");
    }
    
    if (this._internalVars.buttonSwitchLabel) {
        this._internalVars.buttonSwitchLabel.setAttribute("tabIndex", "-1");
    }
    
    if (this._options.initiallyChecked) {
        updateClass.call(this, "button-switch-active", this._internalVars.buttonSwitchToggle, false);
        setCheckboxState.call(this,true);
    }
}

function switchToggle(event:{type: string; keyCode: number; which: number;}) : void {
    if (event.type == "keypress" || event.type == "keyup" && ((event.keyCode || event.which) != 13)) {
        return;
    }

    if (this._internalVars.node.classList.contains("disabled")) {
        return;
    }

    this.toggleSwitch.call(this);

    if (this._options.onSwitchChange) {
        this._options.onSwitchChange(this.getSwitchCheckedState());
    }
}

function setLocalVars() : void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.buttonSwitchInput = this._internalVars.node.querySelector(`input`);
    this._internalVars.buttonSwitchToggle = this._internalVars.node.querySelector(`.button-switch`);
    this._internalVars.buttonSwitchLabel = this._internalVars.node.querySelector(`label`);

    this._internalVars.switchToggleHandler = switchToggle.bind(this);
}

Version.initGdkNPM(component, versions, GdkButtonSwitch);

export { GdkButtonSwitch };