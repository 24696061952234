import baseComponent from '@gdk/base-component';
import Version from '@gdk/version';

let component: string = 'Loader';
let version: string = '1.0.0';
let release: string = '8.31.19';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Loader : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "type",
        isRequired              :   false,
        validate                :   "value",
        possibleValues          :   ["inline","section", "inline--xsmall"],
        errorMessage            :   ["GDK Loader : Type must be defined and set to inline or section"]
    },
    {
        setting                 :   "autoShow",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Loader : autoShow must be a boolean"]
    }

];


const loaderLargeSVG = `
    <svg class="circle-loader" width="62" height="62" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle cx="31" cy="31" r="29">
    </svg>
    <span class="geico-icon icon-loader-g" style="width: 60px; height: 60px; line-height: 60px; font-size: 30px;"></span>
`;



const loaderSmallSVG = `
    <svg class="circle-loader" width="32" height="32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15">
    </svg>
`;

const loaderXSmallSVG = `
    <svg class="circle-loader" width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9">
    </svg>
`;

class GdkLoader{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the loader. If the loader type is a section loader this should be a reference to the loader-container. If the loader type is a inline loader this should be a reference to the loader itself.
     *
     * @param {string} type
     * Used to determine the type of loader. Options: "inline" || "section"
     *
     * @param {boolean} [autoShow=false]
     * Use if you want to set loader to auto show when instantiated
     *
     */

    _internalVars: {
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        node: HTMLElement;
        loaderDom : HTMLElement;
        tick : number;
        loaderShouldPlay : boolean;
        loaderSVG : HTMLElement
    };

    readonly _defaults: object;

    readonly _options: any;

    constructor(options) {

        this._internalVars = {
            node: null,//used for current node
            loaderDom :null,//stores reference to the loader when using a section loader
            tick : 0,
            loaderShouldPlay : false,
            loaderSVG : null,
            contentType: null
        };

        //options with defaults set
        this._defaults = {
            autoShow : false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            buildLoader.call(this);

            if(this._options.autoShow){
                this.show();
            }
        }

    }

    //Public Methods

    /**
     * show()
     * plays and animates loader
     */
    show(){

        this._internalVars.node.style.display = "block";
        setTimeout( ()=> {

            if(this._options.type === "section"){
                this._internalVars.node.classList.add("section-loader--show");
                this._internalVars.loaderDom.classList.add("loader--play");
            }else{
                this._internalVars.node.classList.add("inline-loader--show");
                this._internalVars.node.classList.add("loader--play");
            }

            this._internalVars.loaderShouldPlay = true;
            loaderAnim.call(this);

        }, 10);

    }

    /**
     * hide()
     * hides and stops the loader
     */
    hide(){

        if(this._options.type === "section"){
            this._internalVars.node.classList.remove("section-loader--show");
        }else{
            this._internalVars.node.classList.remove("inline-loader--show");
        }

        setTimeout( ()=> {
            this._internalVars.node.style.display = "none";

            if(this._options.type === "section"){
                this._internalVars.loaderDom.classList.remove("loader--play");
            }else{
                this._internalVars.node.classList.remove("loader--play");
            }

            this._internalVars.loaderShouldPlay = false;

        }, 500);
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (let variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }


    //if its a section loader find the loader
    if(this._options.type === "section"){
        this._internalVars.loaderDom = this._internalVars.node.querySelector(".loader");
        this._internalVars.loaderDom.style.width = "60px";
        this._internalVars.loaderDom.style.height = "60px";
        this._internalVars.loaderDom.style.lineHeight = "60px";
        this._internalVars.loaderDom.style.fontSize = "60px";
    }
}


/**
 * buildLoader()
 * Adds markup and classes this component
 */
function buildLoader() {

    if(this._options.type === "section"){
        this._internalVars.loaderDom.classList.add("loader--large");
        this._internalVars.loaderDom.insertAdjacentHTML('afterBegin', loaderLargeSVG);

        this._internalVars.dashOffsetEnd = 180;
        this._internalVars.dashArrayComplete = 183;
        this._internalVars.dashArrayEnd = 153;
        this._internalVars.tickSpeed = 1;

    }else if(this._options.type === "inline"){
        this._internalVars.node.classList.add("loader--small");
        this._internalVars.node.insertAdjacentHTML('afterBegin', loaderSmallSVG);

        this._internalVars.dashOffsetEnd = 92;
        this._internalVars.dashArrayComplete = 95;
        this._internalVars.dashArrayEnd = 153;
        this._internalVars.tickSpeed = 1.5;

    } else if (this._options.type === "inline--xsmall") {
        this._internalVars.node.classList.add("loader--small");
        this._internalVars.node.insertAdjacentHTML('afterBegin', loaderXSmallSVG);

        this._internalVars.dashOffsetEnd = 62;
        this._internalVars.dashArrayComplete = 65;
        this._internalVars.dashArrayEnd = 153;
        this._internalVars.tickSpeed = 1.5;
    }

    this._internalVars.loaderSVG = this._internalVars.node.querySelector(".circle-loader");

}


function loaderAnim() {
    this._internalVars.tick += this._internalVars.tickSpeed;

    if(this._internalVars.tick > 100) {
        this._internalVars.tick = 0;
    }

    let t = this._internalVars.tick/100;
    let offsetStrokeDashOffset = easeInCubic(t,0,-this._internalVars.dashOffsetEnd,1);
    let offsetStrokeDasharray = easeInCubic(t,0,this._internalVars.dashArrayEnd,1);

    this._internalVars.loaderSVG.style.strokeDashoffset = offsetStrokeDashOffset;
    this._internalVars.loaderSVG.style.strokeDasharray = `${offsetStrokeDasharray} , ${this._internalVars.dashArrayComplete}`;



    if(this._internalVars.loaderShouldPlay){
        requestAnimationFrame(loaderAnim.bind(this));
    }

}


/**
 * easeInQuad()
 * Quadratic easing
 */
function easeInQuad(t, b, c, d) {
    let ts=(t/=d)*t;
    return b+c*(ts);
}
/**
 * easeInCubic()
 * Cubic easing
 */
function easeInCubic(t, b, c, d) {
    let ts=(t/=d)*t*t;
    return b+c*(ts);
}

Version.initGdkNPM(component, version, release, GdkLoader);

export default GdkLoader;