import baseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import { IBaseComponentOptions } from "@gdk/base-component";

const component = "Alert";
const versions = [
    { version: "4.4.0", release: "3.21.24"},
    { version: "4.3.0", release: "10.28.22"},
    { version: "4.2.0", release: "8.12.22"},
    { version: "4.1.0", release: "7.22.22"},
    { version: "4.0.2", release: "04.08.22"}
];

const validateSettings = [{
    setting: "content",
    isRequired: true,
    validate: "type",
    possibleValues: ["string", "object"],
    errorMessage: ["GDK Alert : Content must be defined and set to a DOM selector or Node"]
}];

export {IBaseComponentOptions};

/**
* @desc GDK Alert JavaScript Class
*
* @example <caption>JS Instantiation</caption>
* var alert = GDK.Alert({
*   content: "#your-alert-node-id"
* });
*/
class GdkAlert {
    _internalVars: {
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
        alertContentClass: string;
        alertCloseBtnClass: string;
        alertCloseBtn: NodeListOf<HTMLElement>;
        node: HTMLElement;
    };

    readonly _defaults: object;

    readonly _options: IBaseComponentOptions;

    /**
     * @param {Object} options Settings for the instantiation.
     *
     * @param {string|HTMLElement} options.content A reference to the component node
     */
    constructor(options: IBaseComponentOptions) {
        /**
         * @ignore
         */
        this._internalVars = {
            node: null,
            alertContentClass: "alert-content",
            alertCloseBtnClass: "icon-close",
            alertCloseBtn: null,
            contentType: null
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
             * @ignore
             */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            addCloseButtonClass.call(this);
        }
    }

    //Public Methods

    /**
     * @desc Removes the node from the dom and any events attached
     *
     * @example
     * alert.destroy();
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
function setLocalVars():void {
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.alertCloseBtn = this._internalVars.node.querySelectorAll(`button.${ this._internalVars.alertCloseBtnClass }`);
    this._internalVars.alertCloseBtnPaddingClass = "close-btn";
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents():void {
    for (let i=0; i<this._internalVars.alertCloseBtn.length; i++) {
        this._internalVars.alertCloseBtn[i].addEventListener("click", removeAlert.bind(this, this._internalVars.alertCloseBtn[i]));
    }
}

function removeEvents():void {
    for (let i=0; i<this._internalVars.alertCloseBtn.length; i++) {
        this._internalVars.alertCloseBtn[i].removeEventListener("click", removeAlert.bind(this, this._internalVars.alertCloseBtn[i]));
    }
}


/**
 * removeAlert()
 * checks to remove Alert appropriately
 */
function removeAlert(e: { parentNode: { parentNode: { children: string | any[]; parentNode: any; }; }; }):void {
    if (this._internalVars.node.children.length==1&&e.parentNode.parentNode.children.length==1) {
        fadeAndRemove(this._internalVars.node);
    }
    if (e.parentNode.parentNode.children.length==1) {
        fadeAndRemove(e.parentNode.parentNode.parentNode);
    }
    fadeAndRemove(e.parentNode);
}

/**
 * fade()
 * fade effect
 */
function fadeOutEffect(e: { style: { opacity: number; filter: string; }; }):void {
    let op = 1;  // initial opacity
    const timer = setInterval(function () {
        if (op <= 0.01) {
            clearInterval(timer);
        }
        e.style.opacity = op;
        e.style.filter = `alpha(opacity=${ op * 100})`;
        op -= op * 0.1;
    }, 15);
}

/**
 * removeChildCall
 * Instantiate removal of child element
 */
function removeChildCall(e: { parentNode: { removeChild: (arg0: any) => void; }; }):void {
    e.parentNode.removeChild(e);
}

/**
 * fadeAndRemove()
 * Combine fade effect with delayed removal of element
 */
function fadeAndRemove(e: any):void {
    fadeOutEffect(e);
    setTimeout(removeChildCall, 500, e);
}

function addCloseButtonClass():void {
    for (let i=0; i<this._internalVars.alertCloseBtn.length; i++) {
        this._internalVars.alertCloseBtn[i].parentElement.classList.add(this._internalVars.alertCloseBtnPaddingClass);
    }
}

Version.initGdkNPM(component, versions, GdkAlert);

export { GdkAlert };