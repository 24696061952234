import baseComponent from "@gdk/base-component";
import Version from "@gdk/version";
import {IBaseComponentOptions} from "@gdk/base-component";

const component = "Card Selections";

const versions = [
    { version: "4.2.0", release: "4.28.23"},
    { version: "4.1.1", release: "2.24.23"},
    { version: "4.1.0", release: "1.20.23"},
    { version: "4.0.1", release: "11.11.22"},
    { version: "4.0.0", release: "10.28.22"}
];

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK CardSelections : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "inputType",
        isRequired: false,
        validate: "type",
        possibleValues: ["string"],
        errorMessage: ["GDK CardSelections : inputType must be set to a string"]
    },
    {
        setting: "initialActiveCard",
        isRequired: false,
        validate: "type",
        possibleValues: ["number", "object"],
        errorMessage: ["GDK CardSelections : initialActiveCard must be set to a number"]
    },
    {
        setting: "cardSelectionSet",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK CardSelections : cardSelectionClick must be a function"]
    }
];

export interface ICardSelectionOptions extends IBaseComponentOptions {
    inputType?: string;
    initialActiveCard?: number | Array<number>;
    cardSelectionSet?: (element:HTMLElement) => void;
}

/**
 * @desc GDK Card Selections JavaScript Class
 *
 * @example <caption>JS Instantiation for radio input type</caption>
 * var cardSelections = new GDK.CardSelections({
 *     "content" : "#card-selections-component",
 *     "initialActiveCard" : 1,
 *     "inputType": "radio",
 *     "cardSelectionSet" : function (activeCard) {
 *         console.log('Card Selections: Active Card Set');
 *         console.log(activeCard);
 *     }
 * });
 *
 * @example <caption>JS Instantiation for checkbox input type</caption>
 * var cardSelectionsCheckbox = new GDK.CardSelections({
 *     "content" : "#card-selections-component",
 *     "initialActiveCard" : [1, 2],
 *     "inputType": "checkbox",
 *     "cardSelectionSet" : function (activeCard) {
 *         console.log('Card Selections: Active Card Set');
 *         console.log(activeCard);
 *     }
 * });
 */
class GdkCardSelections {
    /**
     * These are settings for the instantiation.
     * @param {string|Object} content
     *  A reference to the html More Background Pattern node
     *
     *  @param {number|Array<number>} [initialActiveCard]
     *  The number of the item to set as the active card on initialization. Must be greater than 0 and equal to or less than the number of cards.
     *
     *  @param {function} [cardSelectionSet]
     *   A callback function that is triggered when any card is set to active.
     */
    _internalVars: {
        selectedCard: NodeListOf<HTMLElement>;
        cardSelectionsCards: NodeListOf<HTMLElement>;
        cardSelectionSection: HTMLElement;
        node: HTMLElement;
        contentType: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";
    };

    readonly _defaults: {
        inputType: string;
    };

    readonly _options: ICardSelectionOptions;

    constructor(options: ICardSelectionOptions) {
        /**
        * @ignore
        */
        this._internalVars = {
            selectedCard: null,
            cardSelectionsCards: null,
            cardSelectionSection: null,
            node: null,
            contentType: null
        };

        //options with defaults set
        /**
         * @ignore
         */
        this._defaults = {
            inputType: "radio"
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            /**
            * @ignore
            */
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            init.call(this);

            this._internalVars.cardSelectionSection.setAttribute("role", "radiogroup");

            Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element: HTMLElement) => {
                if (!element.getAttribute("tabindex"))
                    element.setAttribute("tabindex", "0");

                if (!element.getElementsByTagName("input")[0].getAttribute("tabindex"))
                    element.getElementsByTagName("input")[0].setAttribute("tabindex", "-1");

                if (this._options.inputType) {
                    if (this._options.inputType === "checkbox") {
                        element.setAttribute("role", "checkbox");
                        element.classList.add("checkboxes");
                        element.getElementsByTagName("input")[0].setAttribute("type", "checkbox");
                        element.getElementsByTagName("span")[0].classList.add("checkbox");
                    } else {
                        element.setAttribute("role", "radio");
                        element.getElementsByTagName("input")[0].setAttribute("type", "radio");
                        element.getElementsByTagName("span")[0].classList.add("radio");
                    }
                } else {
                    element.setAttribute("role", "radio");
                    element.getElementsByTagName("input")[0].setAttribute("type", "radio");
                    element.getElementsByTagName("span")[0].classList.add("radio");
                }
            });
        }
    }

    //Public Methods

    /**
     * @desc Returns the active selected card(s)
     * @return {NodeListOf<HTMLElement>}
     */
    currentActiveCard(): NodeListOf<HTMLElement> {
        return this._internalVars.selectedCard;
    }

    /**
     * @desc Sets the initial active card using an index parameter
     * @param {number|Array<number>} index Number or Array of index Numbers indicating what the active card(s) should be set to
     */
    setActiveCard(index: number | Array<number>): void {
        setInitialActiveCard.call(this, index);
    }

    /**
     * @desc Removes any selections made
     */
    clearSelection(): void {
        clearSelection.call(this);
    }

    /**
     * @desc Removes the node from the dom and any events attached
     */
    destroy(): void {
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (const variableKey in this) {
            if (Object.prototype.hasOwnProperty.call(this, variableKey)) {
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents(): void {
    Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element: HTMLElement) => {
        element.addEventListener("click", this._internalVars.handler);
        element.addEventListener("keyup", this._internalVars.handler);
    });
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents(): void {
    Array.prototype.forEach.call(this._internalVars.cardSelectionsCards, (element: HTMLElement) => {
        element.removeEventListener("click", this._internalVars.handler);
        element.removeEventListener("keyup", this._internalVars.handler);
    });
}

/**
 * init()
 * Sets the initial values
 */
function init(): void {
    if (this._options.initialActiveCard) {
        const index: number | Array<number> = this._options.initialActiveCard;
        setInitialActiveCard.call(this, index);
        this._internalVars.selectedCard = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectedClass}`);
    }
}

/**
 * Removes the class and attributes identifying the card as selected during radio selection
 */
function resetCurrentSelection(): void {
    const selected = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectedClass}`);

    if (selected && this._options.inputType === "radio") {
        Array.prototype.forEach.call(selected, (el: HTMLElement) => {
            el.classList.remove(this._internalVars.cardSelectedClass);
            const checkmark = el.querySelector("span");
            checkmark.classList.remove("icon-confirmation");
            checkmark.classList.add(this._options.inputType);
            el.getElementsByTagName("input")[0].checked = false;
            el.setAttribute("aria-checked", "false");
        });
    }
}

function clearSelection(): void {
    const selected = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectedClass}`);

    Array.prototype.forEach.call(selected, (el: HTMLElement) => {
        el.classList.remove(this._internalVars.cardSelectedClass);
        const checkmark = el.querySelector("span");
        checkmark.classList.remove("icon-confirmation");
        checkmark.classList.add(this._options.inputType);
        el.getElementsByTagName("input")[0].checked = false;
        el.setAttribute("aria-checked", "false");
    });
}

/**
 * Adds attributes identifying the card as selected
 */
function setActiveCardAttributes(): void {
    const cards = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectionsCardClass}`);

    Array.prototype.forEach.call(cards, (el: HTMLElement) => {
        if (el.classList.contains(this._internalVars.cardSelectedClass)) {
            el.getElementsByTagName("input")[0].checked = true;
            el.setAttribute("aria-checked", "true");
        } else {
            el.getElementsByTagName("input")[0].checked = false;
            el.setAttribute("aria-checked", "false");
        }
    });
}

function setCard(cardIndex: number): void {
    if (cardIndex <= this._internalVars.cardSelectionsCards.length && cardIndex > 0) {
        cardIndex = cardIndex - 1;
    } else {
        cardIndex = 0;
    }

    if (!this._internalVars.cardSelectionsCards[cardIndex].classList.contains("disabled")) {
        this._internalVars.cardSelectionsCards[cardIndex].classList.add(this._internalVars.cardSelectedClass);
        const checkmark = this._internalVars.cardSelectionsCards[cardIndex].querySelector("span");
        checkmark.classList.add("icon-confirmation");
        checkmark.classList.add(this._options.inputType);
        setActiveCardAttributes.call(this);
    }
}

/**
 * setInitialActiveCard()
 * Sets the initial active card
 */
function setInitialActiveCard(index: number | Array<number>): void {
    let number = 0;
    if (typeof index === "number") {
        number = index;
    } 
    if (this._options.inputType === "radio") {
        resetCurrentSelection.call(this);
        setCard.call(this, number);
    } else {
        Array.prototype.forEach.call(index, (num: number) => {
            setCard.call(this, num);
        });
    }
}

/**
 * setActiveCard()
 * Sets the active card
 */
function setActiveCard(event: KeyboardEvent): void {
    if (!(event.type == "keypress" || event.type == "keyup" && ((event.keyCode || event.which) != 13))) {
        const target = event.currentTarget as HTMLTextAreaElement;
        const checkmark = target.querySelector("span");
        if (!target.classList.contains("disabled") && !target.classList.contains(this._internalVars.cardSelectedClass)) {
            resetCurrentSelection.call(this);
            checkmark.classList.add("icon-confirmation");
            checkmark.classList.remove(this._options.inputType);
            target.classList.add(this._internalVars.cardSelectedClass);
            setActiveCardAttributes.call(this);
        } else if (!target.classList.contains("disabled") && target.classList.contains(this._internalVars.cardSelectedClass) && this._options.inputType === "checkbox") {
            target.classList.remove(this._internalVars.cardSelectedClass);
            checkmark.classList.remove("icon-confirmation");
            checkmark.classList.add(this._options.inputType);
            target.getElementsByTagName("input")[0].checked = false;
            target.setAttribute("aria-checked", "false");
        }
    }

    this._internalVars.selectedCard = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectedClass}`);
    if (this._options.cardSelectionSet) {
        if (this._internalVars.selectedCard.length === 0) {
            this._internalVars.selectedCard = null;
        }
        this._options.cardSelectionSet(this._internalVars.selectedCard);
    }
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars(): void {
    //determine the type of content passed in
    if (this._internalVars.contentType === "string") {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === "domNode") {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.cardSelectionsObject = this._internalVars.node;

    this._internalVars.cardSelectionSectionClass = "card-selections";
    this._internalVars.cardSelectedClass = "card-selections-selected";
    this._internalVars.cardSelectionsCardClass = "card-selections-card";
    this._internalVars.selectedCard = null;

    this._internalVars.cardSelectionSection = this._internalVars.cardSelectionsObject.querySelector(`.${this._internalVars.cardSelectionSectionClass}`);
    this._internalVars.cardSelectionsCards = this._internalVars.cardSelectionsObject.querySelectorAll(`.${this._internalVars.cardSelectionsCardClass}`);

    this._internalVars.handler = setActiveCard.bind(this);
}

Version.initGdkNPM(component, versions, GdkCardSelections);

export { GdkCardSelections };